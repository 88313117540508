// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-late-sales-tsx": () => import("./../../../src/pages/late-sales.tsx" /* webpackChunkName: "component---src-pages-late-sales-tsx" */),
  "component---src-pages-recipes-tsx": () => import("./../../../src/pages/recipes.tsx" /* webpackChunkName: "component---src-pages-recipes-tsx" */),
  "component---src-pages-static-health-tsx": () => import("./../../../src/pages/StaticHealth.tsx" /* webpackChunkName: "component---src-pages-static-health-tsx" */),
  "component---src-templates-landing-page-template-tsx": () => import("./../../../src/templates/LandingPageTemplate.tsx" /* webpackChunkName: "component---src-templates-landing-page-template-tsx" */),
  "component---src-templates-product-group-page-template-tsx": () => import("./../../../src/templates/ProductGroupPageTemplate.tsx" /* webpackChunkName: "component---src-templates-product-group-page-template-tsx" */)
}

